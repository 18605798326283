<template>
  <div id="app">
    <div class="main-wrapper">
      <div class="main-content">
        <div class="col-12 col-md-6 col-lg-6 offset-md-3 offset-lg-3 p-0">
          <div class="container container-welcome text-center">
            <h1 class="text-kamsia font-weight-bold">
              Login dan mulai eksplorasi barangmu!
            </h1>
            <p class="text-kamsia mb-3 px-1">
              Satu langkah lagi sebelum memulai petualangan pencarian barang-mu
            </p>
            <Form
              class="text-left"
              @submit="handleLogin"
              :validation-schema="schema"
              v-slot="{ errors }"
            >
              <div class="form-group">
                <label for="email">Email</label>
                <Field
                  type="email"
                  class="form-control"
                  :class="{ 'is-invalid': errors.email }"
                  name="email"
                  id="email"
                />
                <ErrorMessage name="email" class="invalid-feedback" as="div" />
              </div>
              <div class="form-group">
                <label for="password">Password</label>
                <div class="input-group">
                  <Field
                    :type="see_confirm_password == true ? 'password' : 'text'"
                    :class="{ 'is-invalid': errors.password }"
                    class="form-control"
                    name="password"
                    id="password"
                  />
                  <div class="input-group-append">
                    <div
                      class="input-group-text"
                      :class="{ 'border-danger': errors.password }"
                    >
                      <font-awesome-icon
                        icon="fa-regular fa-eye-slash"
                        @click="handlePassword()"
                      />
                    </div>
                  </div>
                  <ErrorMessage
                    name="password"
                    class="invalid-feedback"
                    as="div"
                  />
                </div>
              </div>
              <div class="float-right">
                <a href="/reset-password" class="text-small">Lupa Password?</a>
              </div>
              <button
                :disabled="loading"
                id="btn-login"
                class="btn btn-moopo btn-lg btn-rounded text-white w-100 mb-2 mt-3"
              >
                <span
                  v-show="loading"
                  class="spinner-border spinner-border-sm"
                ></span>
                Masuk Sekarang
              </button>
            </Form>
            <a
              v-if="loginGoogle"
              href="/login-google"
              class="btn btn-icon btn-rounded icon-left btn-light btn-lg btn-block mb-2"
            >
              <font-awesome-icon icon="fa-brands fa-google" />
              &nbsp; Masuk dengan Google
            </a>
            <div class="w-100 text-center mt-1">
              Belum punya akun?
              <a href="/register" class="text-kamsia">Daftar Sekarang</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- modal -->
  <template v-if="PPModal">
    <div
      class="modal fade show d-block"
      ref="PPModal"
      tabindex="-1"
      role="dialog"
      id="PPModal"
    >
      <div class="vertical-alignment-helper px-3">
        <div class="modal-dialog vertical-align-center" role="document">
          <div class="modal-content">
            <Form class="text-left">
              <div class="modal-header">
                <div class="col-12">
                  <div class="modal-title text-center text-150rem text-danger">
                    Terms Conditions
                  </div>
                </div>
              </div>
              <div class="modal-body scrollable-content">
                <div class="text-dark text-1rem">Pendahuluan</div>
                <br />
                <div
                  class="tw-text-md tw-leading-relaxed tw-font-normal tw-text-gray-500"
                >
                  PT Imaji Cipta (mempunyai produk yang disebut “Kamsia”).
                  Adalah suatu perseroan terbatas yang salah satu jenis usahanya
                  berkecimpung pada bidang portal penjualan di bidang kuliner
                  yaitu Kamsia. PT Imaji Cipta dalam hal ini menyediakan
                  Platform penjualan elektronik (e-commerce) di mana Pengguna
                  dapat melakukan transaksi jual-beli, menggunakan berbagai
                  fitur serta layanan yang tersedia. Setiap pihak yang berada
                  pada wilayah Negara Kesatuan Republik Indonesia bisa mengakses
                  Platform Kamsia untuk membuka lapangan penjualan di bidang
                  kuliner, menggunakan layanan, atau hanya sekedar mengakses /
                  mengunjungi. <br /><br />Syarat & ketentuan yang telah
                  ditetapkan untuk mengatur pemakaian jasa yang ditawarkan oleh
                  PT. Imaji Cipta terkait penggunaan perangkat lunak Kamsia.
                  Pengguna disarankan membaca dengan seksama karena dapat
                  berdampak pada hak dan kewajiban Pengguna di bawah aturan.
                  dengan mendaftar akun Kamsia dan /atau memakai Platform
                  Kamsia, maka Pengguna dianggap sudah membaca, mengerti, tahu
                  serta menyetujui seluruh isi pada aturan Penggunaan. Jika
                  pengguna tidak menyetujui salah satu, pesebagian, atau semua
                  isi syarat & ketentuan, maka pengguna tidak diperkenankan
                  memakai layanan Kamsia.
                </div>
                <br />
                <div class="text-dark text-1rem">Definisi</div>
                <br />
                <div class="text-dark">
                  Dalam Aturan Penggunaan istilah-istilah di bawah ini mempunyai
                  arti sebagai berikut:
                </div>
                <br />
                <div
                  class="tw-text-md tw-font-normal tw-leading-relaxed tw-text-gray-500"
                >
                  1.) PT. Imaji Cipta (Kamsia) adalah suatu perseroan terbatas
                  yang menjalankan kegiatan usaha jasa aplikasi Kamsia, yakni
                  aplikasi pencarian lapak dalam bidang Kuliner yang dijual oleh
                  penjual terdaftar. Yang selanjutnya disebut Kamsia.
                  <br /><br />2.) Akun adalah data tentang Pengguna, minimum
                  terdiri dari nama, password, nomor telepon, dan email yang
                  wajib diisi oleh Pengguna Terdaftar. <br /><br />3.) Platform
                  Kamsia adalah situs resmi kam5ia.com dan seluruh website resmi
                  beserta aplikasi resmi Kamsia (berbasis Android dan iOS) yang
                  dapat diakses melalui perangkat komputer dan/atau perangkat
                  seluler Pengguna. <br /><br />4.) Pembeli adalah Pengguna
                  terdaftar yang melakukan permintaan atas Makanan atau minuman
                  yang dijual oleh Penjual di Aplikasi Kamsia. <br /><br />5.)
                  Penjual adalah Pengguna terdaftar yang melakukan kegiatan buka
                  toko dan/atau melakukan penawaran atas suatu Makanan dan
                  minuman kepada para Pengguna dan /atau Pembeli.
                  <br /><br />6.) Layanan adalah secara kolektif: (i) Platform
                  Kamsia; (ii) Konten, fitur, layanan, dan fungsi apa pun yang
                  tersedia di atau melalui Platform oleh atau atas nama Kamsia,
                  termasuk Layanan Partner; dan pemberitahuan email, tombol,
                  widget, dan iklan.
                </div>
                <br />
                <div class="text-dark text-1rem">
                  Pengguna, Penjual, Akun, Password & Keamanan
                </div>
                <br />
                <div
                  class="tw-text-md tw-leading-relaxed tw-font-normal tw-text-gray-500"
                >
                  1.) Pengguna wajib berusia minimal 18 tahun (kecuali
                  ditentukan lain oleh peraturan perundang-undangan yang berlaku
                  di Indonesia). Pengguna yang belum genap berusia 18 tahun
                  wajib memperoleh persetujuan dari orang tua atau wali untuk
                  menggunakan dan /atau mengakses layanan di Platform Kamsia dan
                  bertanggung jawab atas segala biaya yang timbul terkait
                  penggunaan layanan di Platform Kamsia. <br /><br />2.)
                  Pengguna harus memahami bahwa 1 (satu) nomor telepon hanya
                  dapat digunakan untuk mendaftar 1 (satu) akun Pengguna Kamsia,
                  kecuali bagi Pengguna yang telah memiliki beberapa akun dengan
                  1 (satu) nomor telepon sebelumnya <br /><br />3.) Pengguna
                  yang telah mendaftar berhak bertindak sebagai: Pembeli dan
                  Penjual. <br /><br />4.) Penjual diwajibkan membayar biaya
                  pembukaan toko. Penjual berhak melakukan pengaturan terhadap
                  barang yang akan diperdagangkan di lapak pribadi Penjual.
                  <br /><br />5.) Kamsia memiliki hak untuk melakukan tindakan
                  yang perlu atas setiap dugaan pelanggaran Syarat & ketentuan
                  sesuai dengan hukum yang berlaku, yakni tindakan berupa
                  penghapusan Barang, penutupan toko, suspensi akun, sampai
                  penghapusan akun pengguna. <br /><br />6.) Pengguna menyetujui
                  untuk tidak menggunakan dan/atau mengakses sistem Kamsia
                  secara langsung atau tidak langsung, baik keseluruhan atau
                  sebagian dengan virus, perangkat lunak, atau teknologi lainnya
                  yang dapat mengakibatkan melemahkan, merusak, mengganggu dan
                  menghambat, membatasi, mengambil alih fungsionalitas serta
                  integritas dari sistem perangkat lunak atau perangkat keras,
                  jaringan, dan/atau data pada Aplikasi Kamsia. <br /><br />7.)
                  Pengguna wajib mengetahui bahwa detail informasi berupa data
                  diri nama, alamat usaha, nomor telepon akun milik Pengguna
                  akan diterima oleh pihak Penjual dalam kemudahan bertransaksi
                  dan berfungsi sebagai database penjual sendiri <br /><br />8.)
                  Penjual harus mengetahui bahwa detail informasi milik Pengguna
                  adalah rahasia, dan karenanya Penjual tidak akan mengungkapkan
                  detail informasi akun Pengguna kepada Pihak Ketiga mana pun
                  kecuali untuk kegiatan jual beli dalam aplikasi Kamsia.
                  <br /><br />9.) Penjual setuju untuk menanggung setiap risiko
                  terkait pengungkapan informasi Akun Pengguna kepada Pihak
                  Ketiga mana pun dan bertanggung jawab penuh atas setiap
                  konsekuensi yang berkaitan dengan hal tersebut.
                  <br /><br />10.) Pengguna dilarang menggunakan Platform Kamsia
                  untuk melanggar peraturan yang ditetapkan oleh hukum di
                  Indonesia maupun di negara lainnya. <br /><br />11.) Pengguna
                  dilarang mendistribusikan virus atau teknologi lainnya yang
                  dapat membahayakan aplikasi Kamsia, kepentingan dan/atau
                  properti dari Pengguna lain, maupun instansi Pemerintahan.
                  <br /><br />12.) Pengguna dilarang menggunakan Platform Kamsia
                  untuk tujuan komersial dan melakukan transfer/menjual akun
                  Pengguna ke Pengguna lain atau ke pihak lain dengan tujuan
                  apapun. <br /><br />13.) Pengguna wajib menghargai hak-hak
                  Pengguna lainnya dengan tidak memberikan informasi pribadi ke
                  pihak lain tanpa izin pihak yang bersangkutan.
                  <br /><br />14.) Pengguna wajib membaca, memahami serta
                  mengikuti semua ketentuan yang diatur dalam Aturan Penggunaan
                  ini.
                </div>
                <br />
                <div class="text-dark text-1rem">Ketentuan Lain</div>
                <br />
                <div
                  class="tw-text-md tw-leading-relaxed tw-font-normal tw-text-gray-500"
                >
                  1.) Apabila pengguna mempergunakan fitur/layanan yang tersedia
                  dalam Website/Aplikasi Kamsia, maka Pengguna dengan ini
                  menyatakan telah memahami dan menyetujui segala syarat dan
                  ketentuan yang diatur khusus sehubungan dengan fitur/layanan
                  yang digunakan. <br /><br />2.) Segala hal yang belum dan/atau
                  tidak diatur dalam syarat dan ketentuan khusus dalam fitur
                  tersebut maka akan sepenuhnya merujuk pada syarat dan
                  ketentuan Kamsia secara umum. <br /><br />3.) Dengan
                  menyetujui Syarat dan Ketentuan, maka Pengguna telah dianggap
                  paham dan mengikuti Kebijakan Privasi Kamsia.
                </div>
                <br />
                <div class="text-dark text-1rem">
                  Pembaruan & Perubahan Aturan Penggunaan
                </div>
                <br />
                <div
                  class="tw-text-md tw-leading-relaxed tw-font-normal tw-text-gray-500"
                >
                  Kamsia memiliki hak untuk melakukan pembaruan dan/atau
                  perubahan Aturan Penggunaan dari waktu ke waktu jika
                  diperlukan demi keamanan dan kenyamanan Pengguna di Platform
                  Kamsia. Pengguna harus setuju untuk membaca secara saksama dan
                  memeriksa Aturan Penggunaan ini dari waktu ke waktu untuk
                  mengetahui pembaruan dan/atau perubahan apapun. Dengan tetap
                  mengakses dan menggunakan layanan Kamsia, maka pengguna
                  dianggap menyetujui perubahan-perubahan dalam Syarat &
                  Ketentuan kami.
                </div>
              </div>
              <div class="modal-footer center-content">
                <button @click="agree()" class="btn text-white bg-moopo">
                  Setuju
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script setup>
import axios from "axios";
import * as yup from "yup";
import { useRouter } from "vue-router";
import { inject, onMounted, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";

const route = useRouter();
const PPModal = ref(false);
const loading = ref(false);
const loginGoogle = ref(true);
const api_url = inject("mainUrl") + "/api/auth/login";

const schema = yup.object().shape({
  email: yup.string().email("Email Tidak Valid").required("Email harus diisi"),
  password: yup.string().required("Password harus diisi"),
});
const email = ref("");
const name = ref("");
const photo = ref("");
const see_confirm_password = ref(true);

onMounted(() => {
  if (localStorage.getItem("name_google") !== null) {
    name.value = localStorage.getItem("name_google");
  }
  if (localStorage.getItem("photo_google") !== null) {
    photo.value = localStorage.getItem("photo_google");
  }
  if (localStorage.getItem("email_google") !== null) {
    email.value = localStorage.getItem("email_google");
    handleLoginGoogle();
  }
  if (localStorage.getItem("is_ios") !== null) {
    if (localStorage.getItem("is_ios") == "true") {
      loginGoogle.value = false;
    }
  }
});

function handlePassword() {
  see_confirm_password.value = !see_confirm_password.value;
}

async function handleLoginGoogle() {
  loading.value = true;

  let form = new FormData();
  form.append("auth_by_google", true);
  form.append("name", name.value);
  form.append("email", email.value);
  form.append("img", photo.value);
  if (localStorage.getItem("device_id") !== null) {
    form.append("device_id", localStorage.getItem("device_id"));
  }

  axios
    .post("https://dev.jiitu.co.id/api/auth/login", form)
    .then((res) => {
      // console.log(res);
      if (res["status"] == 200) {
        // Store the user's token and other data in local storage
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.user));

        PPModal.value = true;
      }
    })
    .catch((err) => {
      console.log(err.response.data.message);
      loading.value = false;
    });
}

function handleLogin(user, actions) {
  loading.value = true;

  let form = new FormData();
  form.append("email", user.email);
  form.append("password", user.password);
  if (localStorage.getItem("device_id") !== null) {
    form.append("device_id", localStorage.getItem("device_id"));
  }
  form.append("app_id", "MPO");

  axios
    .post("https://dev.jiitu.co.id/api/auth/login", form)
    .then((res) => {
      // console.log(res);
      if (res["status"] == 200) {
        // Store the user's token and other data in local storage
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.user));

        PPModal.value = true;
      }
    })
    .catch((err) => {
      console.log(err.response.data.message);
      actions.setFieldError("email", err.response.data.message);
      loading.value = false;
    });
}

function agree() {
  localStorage.setItem("name_google", "");
  localStorage.setItem("email_google", "");
  localStorage.setItem("photo_google", "");
  PPModal.value = false;
  if (route.options.history.state.back == "/transaction") {
    route.push("/transaction");
  } else {
    route.push("/home");
  }
}
</script>
-->

<style scoped>
* {
  font-family: "Nunito", sans-serif;
}

h1 {
  font-size: 1.5rem;
}

p {
  font-size: 1rem !important;
  font-weight: 500;
}

.main-wrapper {
  background-color: #fff;
  height: 100vh;
  /* Set the height to 100% of the viewport height */
}

.main-content {
  width: 100% !important;
}

.btn.btn-lg {
  padding: 0.6rem 1.5rem;
  font-size: 14px;
  font-weight: 500;
}

.btn-rounded {
  border-radius: 20px;
}

.btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  padding: 0.3rem 0.8rem;
  letter-spacing: 0.5px;
}

.text-kamsia {
  color: #1b97df;
}

.main-wrapper {
  background-color: #fff;
  height: 100vh;
  /* Set the height to 100% of the viewport height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scrollable-content {
  max-height: 400px;
  overflow-y: auto;
}
</style>
