export default {
  install(app) {
    app.config.globalProperties.$numFormat = (key) => {
      return Number(key).toLocaleString().replaceAll(",", ".");
    };

    app.config.globalProperties.$convertTo12HourFormat = (time24) => {
      let formattedTime;
      if(time24){
        let [hours, minutes] = time24.split(':').map(Number);
        let period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} ${period}`;
      } else {
        formattedTime = '00:00 AM';
      }

      return formattedTime;
    };

    app.config.globalProperties.$formatDate = (dateString) => {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'];
  
      const date = new Date(dateString);
      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();
      
      return `${day} ${month} ${year}`;
    };

    app.config.globalProperties.$formatRegion = (region) => {
      return region
        .toLowerCase()
        .split(" ")
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ")
        .replace("Kabupaten", "Kab.");
    };
    app.config.globalProperties.$humanNumber = (n) => {
      let unit = "";
      if (n >= 1000000) {
        n = n / 1000000;
        unit = "jt";
      } else if (n >= 1000) {
        n = n / 1000;
        unit = "rb";
      }

      return `${Math.round(n * 10) / 10} ${unit}`;
    };
  },
};
